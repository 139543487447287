import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, Document } from '@contentful/rich-text-types';

export interface IRichTextProps {
    content: string | Document | any;
    className?: string;
    textAlign?: 'left' | 'center';
}

// const embeddedComponentsMap: Record<string, React.FC<any>> = {
//     link: Link,
//     button: Button,
// };

function renderOptions(links : any) {
    // create an asset map
    const assetMap = new Map();
    if (links) {
        // loop through the assets and add them to the map
        if (links.assets) {
            for (const asset of links.assets?.block) {
              assetMap.set(asset.sys.id, asset);
            }
        }
      
        // create an entry map
        const entryMap = new Map();
        // loop through the block linked entries and add them to the map
        if (links.entries) {
            for (const entry of links.entries?.block) {
                entryMap.set(entry.sys.id, entry);
              }
              // loop through the inline linked entries and add them to the map
             for (const entry of links.entries?.inline) {
               entryMap.set(entry.sys.id, entry);
             }
        }
      
        
      
    }
  
    return {
    renderNode: {
        // [INLINES.EMBEDDED_ENTRY]: (node: any) => {
        //     const props = node.data.target.fields;

        //     return (
        //         <ComponentBuilder blocks={[props]} />
        //     );
        // },

        [INLINES.HYPERLINK]: (node: any, children: any) => {
            return (
                <a href={node.data.uri}>
                    {children}
                </a>
            );
        },

        [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const asset = assetMap.get(node.data.target.sys.id);
            return (
                <img src={asset.url} alt="My image alt text" />
              );
            },

        [BLOCKS.PARAGRAPH]: (node: any, children: any) =>
            <p className="">{children}</p>,

        [BLOCKS.HEADING_1]: (node: any, children: any) =>
            <h1>{children}</h1>,

        [BLOCKS.HEADING_2]: (node: any, children: any) =>
            <h2>{children}</h2>,

        // [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        //     const props = node.data.target;

        //     return (<ComponentBuilder content={props} />);
        // },
    },
    renderText: (text: string) => text.split('\n').reduce(
        // @ts-ignore
        (children, textSegment, index) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
        ], [],
    ),
    }
};

export default function RichTextRenderer({content, className, textAlign} : IRichTextProps ) {
    return (
        <div className={className}>
            {typeof content === 'string'
                ? (
                    <div>{content}</div>
                )
                : (
                    <>
                        {documentToReactComponents(content.json, renderOptions(content.links))}
                    </>
                )}
        </div>
    );
};
