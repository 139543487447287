import { FC } from 'react';
import RichTextRenderer from '../RichText';

export interface IInfoBlock {
    content: {
        column1?: {
            json: {};
            links: any;
        }
        column2?: {
            json: {};
            links: any;
        }
        column3?: {
            json: {};
            links: any;
        }
        column4?: {
            json: {};
            links: any;
        }
        variant: string;
    }
}

const InfoBlock: FC<IInfoBlock> = function ({ content}) {
    return (
        <section className={`${content.variant === '4 columns' ? 'bg-whitepure' : 'bg-secondary'}`} >
            <div className="container">
                <div className="flex max-md:flex-wrap gap-y-10 md:gap-x-4">
                    {content.variant === "2 columns" && <>
                        {content.column1 && <div className="w-full sm:w-1/2 sm:pr-4">
                            <RichTextRenderer content={content.column1} className="text" />
                        </div>}
                        {content.column2 && <div className="w-full sm:w-1/2 sm:pl-4">
                            <RichTextRenderer content={content.column2} className="text" />
                        </div>}
                    </>}
                    {content.variant === "3 columns" && <>
                        {content.column1 && <div className="w-full sm:w-1/2 md:w-1/4 text-center">
                            <RichTextRenderer content={content.column1} />
                        </div>}
                        {content.column2 && <div className="w-full sm:w-1/2 md:w-1/4 text-center">
                            <RichTextRenderer content={content.column2} />
                        </div>}
                        {content.column3 && <div className="w-full md:w-2/4 lg:pl-10 flex flex-col justify-end text-center">
                        <div className="bg-whitepure border-tertiary border-opacity-20 p-10 relative max-w-[25rem] lg:max-w-[30rem] max-md:mx-auto mx-4 lg:ml-auto">
                            <div className="opacity-20 absolute w-[2.5rem] left-0 -top-6 text-tertiary">
                                <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier"><path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35l.539-.222.474-.197-.485-1.938-.597.144c-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.318.142-.686.238-1.028.466-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.945-.33.358-.656.734-.909 1.162-.293.408-.492.856-.702 1.299-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539l.025.168.026-.006A4.5 4.5 0 1 0 6.5 10zm11 0c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35l.539-.222.474-.197-.485-1.938-.597.144c-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162-.293.408-.492.856-.702 1.299-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539l.025.168.026-.006A4.5 4.5 0 1 0 17.5 10z"></path>
                                    </g>
                                </svg>
                            </div>
                            <RichTextRenderer content={content.column3} className="text-center font-semibold flex flex-col justify-center h-full mx-auto" />
                        </div>
                    </div>}
                    </>
                    }
                    {content.variant === "4 columns" && <>
                        {content.column1 && <div className="w-full sm:w-1/2 md:w-1/4 text-center">
                            <RichTextRenderer content={content.column1} />
                        </div>}
                        {content.column2 && <div className="w-full sm:w-1/2 md:w-1/4 text-center">
                            <RichTextRenderer content={content.column2} />
                        </div>}
                        {content.column3 && <div className="w-full sm:w-1/2 md:w-1/4 text-center">
                            <RichTextRenderer content={content.column3} />
                        </div>}
                        {content.column4 && <div className="w-full sm:w-1/2 md:w-1/4 text-center">
                            <RichTextRenderer content={content.column4} />
                        </div>}
                    </>
                    }
                </div>
            </div>
        </section>
    );
};

export default InfoBlock;
